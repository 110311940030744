import React, { useState } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import Hero from '../components/hero'
import SectionContainer from '../components/layout/sectionContainer'
import SearchBox from '../components/common/searchBox'
import RichTextRenderer from '../components/richTextRenderer'
import * as vintermerStyling from './vintermer.module.scss'
import * as pageStyling from './pageStyling.module.scss'

const WineTermsPage = () => {
    const [query, setQuery] = useState(null)

    const data = useStaticQuery(graphql`
        {
            allContentfulWineTerm {
                edges {
                    node {
                        id
                        term
                        slug
                        anchor
                        explanation {
                            raw
                        }
                    }
                }
            }
        }
    `)

    const handleInputChange = (event) => {
        setQuery(event.target.value)
    }

    const allWineTerms = data.allContentfulWineTerm.edges.reduce(
        (listOfWineTerms, { node: wineTerm }) => {
            const term = wineTerm.term.toLowerCase()
            if (query === null || term.includes(query.toLowerCase())) {
                listOfWineTerms.push(wineTerm)
            }
            return listOfWineTerms
        },
        []
    )

    allWineTerms.sort((a, b) => a.term.localeCompare(b.term))

    return (
        <>
            <GatsbySeo
                title="Vintermer"
                description="invärlden innehåller många olika termer och låneord, vi har tagit fram en lista som förklarar dem enkelt för dig. Varsågod!"
            />
            <Hero
                heading="Vintermer"
                body="Vinvärlden innehåller många olika termer och låneord, vi har tagit fram en lista som förklarar dem enkelt för dig. Varsågod!"
            />
            <div className={pageStyling.defaultWrapper}>
                <SectionContainer
                    title="Alla vintermer på Vinologik"
                    subtitle="Vi fyller regelbundet på med nya ord."
                    asColumn={true}
                >
                    <SearchBox
                        placeholder={'Sök efter vinterm...'}
                        onChange={handleInputChange}
                        inverted={true}
                    />
                    {!allWineTerms.length && (
                        <div>
                            <h4>
                                Hittade inget resultat, försök med en annan
                                sökning.
                            </h4>
                        </div>
                    )}
                    {allWineTerms.map((wineTerm) => (
                        <div
                            key={wineTerm.id}
                            className={vintermerStyling.wineTerm}
                            id={wineTerm.anchor}
                        >
                            <h3 className={vintermerStyling.headline}>
                                {wineTerm.term}
                            </h3>
                            <RichTextRenderer richText={wineTerm.explanation} />
                        </div>
                    ))}
                </SectionContainer>
            </div>
        </>
    )
}

export default WineTermsPage
